@import url(https://fonts.googleapis.com/css2?family=Chivo:wght@900&family=VT323&family=PT+Sans&family=Comfortaa&family=Roboto&family=Material+Icons&display=swap);
@-webkit-keyframes GradientBackground {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes GradientBackground {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-webkit-keyframes Pulse {
  0% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
  25% {
    fill: #ff80ab;
    transform: scale(3) rotate(-10deg);
  }
  50% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
  75% {
    fill: #ff9100;
    transform: scale(3) rotate(10deg);
  }
  100% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
}
@keyframes Pulse {
  0% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
  25% {
    fill: #ff80ab;
    transform: scale(3) rotate(-10deg);
  }
  50% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
  75% {
    fill: #ff9100;
    transform: scale(3) rotate(10deg);
  }
  100% {
    fill: #ff1744;
    transform: scale(2) rotate(0deg);
  }
}

body {
  margin: 0;
}

